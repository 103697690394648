@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600&family=Exo:wght@300;400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap");
.expBG {
  background-image: url(../../assets/landingPage/portfolio/years.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}

/* ================================= */
/* ============= CARD ============== */
.divCard {
  padding-left: "140px" !important;
  padding-right: "140px" !important;
  padding-bottom: "70px" !important;
}

.cardMain {
  height: "500px";
  background-color: "#f1f8fb";
}

/* ================================= */
/* ============= ADS ============== */
.download {
  background-image: url(../../assets/landingPage/dlBG.png);
  height: 600px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ==================================== */
/* ============= CONTACT ============== */
.contactStrip {
  background-image: url("../../assets/landingPage/statsBG1.png");
  background-size: cover;
  margin-left: 175px;
  margin-right: 175px;
  text-align: center;
  border-radius: 15px;
  height: "50px";
}

.contactSplitBG {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    #000000 50%,
    #000000 100%
  );
}
