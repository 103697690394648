html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding, 110px);
  /* transition-delay: 2s; */
}

.servmoreLogo {
  height: 80px;
}

.navbar {
  background-color: transparent;
  /* color: "white"; */
}

.navbar.colorChange {
  /* color: "black"; */
  background-color: rgba(34, 37, 114, 0.93);
}
