.welcomeBG {
  background-image: url("/src/assets/landingPage/homeBG1.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 753px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008cba;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.overlay.hover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008cba;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
}

/* =================================== */

.newSection {
  margin-top: "100px" !important;
  text-align: "center" !important;
  padding-left: "30px";
  padding-right: "30px";
}

.divTitle {
  font-size: "15px";
  font-weight: 500;
  letter-spacing: "4px" !important;
}

.secTitle {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 8vh !important;
  font-weight: 600 !important;
}

.stats {
  background-image: url("../../assets/landingPage/statsBG1.png");
  background-size: cover;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  border-radius: 15px;
}

.splitBG {
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    #f1f8fb 50%,
    #f1f8fb 100%
  );
}
