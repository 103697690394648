@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600&family=Exo:wght@300;400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap");

.welcomeBG {
  background-image: url("/src/assets/landingPage/homeBG1.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 753px;
}

/* ======================================== */
/* ============= NEW SECTION ============== */
.newSection {
  margin-top: "100px" !important;
  text-align: "center" !important;
  padding-left: "30px";
  padding-right: "30px";
}

.divTitle {
  font-size: "15px";
  font-weight: 500;
  letter-spacing: "4px" !important;
}

.secTitle {
  font-family: "Barlow Condensed", sans-serif !important;
  font-size: 8vh !important;
  font-weight: 600 !important;
}

.stats {
  background-image: url("../../assets/landingPage/statsBG1.png");
  background-size: cover;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  border-radius: 15px;
}

.splitBG {
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    #f1f8fb 50%,
    #f1f8fb 100%
  );
}

/* ======================================= */
/* ============= Experience ============== */
.expBG {
  background-image: url(../../assets/landingPage/portfolio/years.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}

/* ================================= */
/* ============= ADS ============== */
.download {
  background-image: url(../../assets/landingPage/dlBG.png);
  height: 600px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ==================================== */
/* ============= CONTACT ============== */
.contactStrip {
  background-image: url("../../assets/landingPage/statsBG1.png");
  background-size: cover;
  margin-left: 175px;
  margin-right: 175px;
  text-align: center;
  border-radius: 15px;
  height: "50px";
}

.contactSplitBG {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    #000000 50%,
    #000000 100%
  );
}

.textFieldBG.MuiFilledInput-root {
  background-color: #f1f8fb;
}
